@import url("./utils/assets/css/normilize.css");
@import url("./utils/assets/fonts/Inter/inter.css");
@import url("./utils/assets/fonts/Switzer/switzer.css");
@import url("./utils/assets/fonts/Manrope/manrope.css");

*:not(#threads, #thread-wrapper)::-webkit-scrollbar {
  display: none;
  width: 0;
}

html,
body {
  overscroll-behavior-x: none;
}
::-webkit-scrollbar {
  display: none;
  width: 0;
}
body {
  margin: 0 auto;
  font-family: Switzer;
  box-sizing: border-box;
}

body.page-threads,
body.page-inbox,
body.page-inbox #app {
  overflow: hidden;
  overscroll-behavior-y: contain;
}

html,
html body {
  height: calc(var(--vh, 1dvh) * 100);
}

#app {
  height: 100%;
  padding-top: 59px;
  box-sizing: border-box;
}

.main-container {
  height: 100%;
}

@media screen and (min-width: 767px) {
  .main-container {
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: max-content 1fr;
    max-width: 1440px;
    margin: 0 auto;
    height: 100vh;
  }
  header-section {
    grid-column: 2;
    grid-row: 1;
  }

  sidebar-menu {
    grid-column: 1;
    grid-row: 1 / span 2;
  }

  #app {
    grid-column: 2;
    grid-row: 2;
    overflow-y: scroll;
    padding-top: 0;
  }
  html {
    height: 100%;
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
  }
  body {
    margin: 0 auto;
    font-family: Switzer;
    box-sizing: border-box;
    height: 100%;
    overflow: hidden;
  }
}

@media screen and (max-width: 767px) {
  body.page-startups #app {
    padding-top: 0px;
  }
}

/* variables for light mode */
body {
  /* background colors */
  --bg-grey-light: #f9fafa;
  --bg-white: #fff;
  --bg-blue-dark: rgba(12, 26, 61, 1);
  --bg-blue-light: rgba(53, 89, 199, 1);
  /* text colors */
  --text-white: #fff;
  --text-blue-dark: rgba(12, 26, 61, 1);
  --text-blue-light: rgba(53, 89, 199, 1);
  --text-sea: rgba(33, 82, 82, 1);
  --text-brown: rgba(82, 68, 33, 1);
  --text-dark-green: rgba(61, 82, 33, 1);
  /*indicators and so on*/
  --indicator-active: rgba(19, 217, 122, 1);
  --indicator-activity: rgba(247, 95, 0, 1);
  --avatar-fill-yellow: #f6ebb7;
  --badge-bg-light-blue: #f0f2fa;
  --badge-bg-sea: rgba(228, 249, 249, 1);
  --badge-border-sea: rgba(148, 207, 207, 1);
  --badge-bg-brown: rgba(249, 243, 228, 1);
  --badge-border-brown: rgba(207, 190, 148, 1);
  --badge-bg-green: rgba(240, 249, 228, 1);
  --badge-border-green: rgba(182, 207, 148, 1);
  --border-light-gray: #d8dee4;

  /*items*/
  --bg-nav: rgba(12, 26, 61, 1);
}

/* variables for dark mode */
.dark {
  --bg-nav: rgba(0, 0, 0, 1);
}
